import { inject, Injectable, signal } from '@angular/core';
import {
  ChangePasswordInput,
  User,
  UserInput,
  VerifyEmailInput,
} from '@gqlSchema';
import { Apollo } from 'apollo-angular';
import { catchError, first, map, Observable, of, tap } from 'rxjs';
import { UiService } from '../../core/services/ui.service';
import { LoginUser } from '../../core/types/GQLOperationNames.type';
import {
  AcceptInvitationMutation,
  ChangePasswordMutation,
  ClubInvitationByToken,
  LoginUserMutation,
  RegisterUserMutation,
  RejectInvitationMutation,
  RequestResetPasswordMutation,
  ResendVerificationEmailMutation,
  ResetPasswordMutation,
  SignInWithAppleIdMutation,
  SignInWithGoogleMutation,
  SignUpWithAppleIdMutation,
  SignUpWithGoogleMutation,
  VerifyEmailMutation,
} from '../graphql/authentication.fragments';
import { Permission } from '../util/permissions';
import { Roles } from '../util/roles';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected apollo = inject(Apollo);
  ui = inject(UiService);

  user = signal<LoginUser>({} as LoginUser);

  constructor() {
    let storedUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    if (!!storedUser) this.user.set(storedUser);
  }

  public setSoredUser(newUser: LoginUser) {
    this.user.set(newUser);
    localStorage.setItem('currentUser', JSON.stringify(this.user()));
  }

  public loginUser(data: any): Observable<User> {
    return this.apollo
      .mutate<any>({
        mutation: LoginUserMutation,
        fetchPolicy: 'network-only',
        variables: { request: { ...data } },
      })
      .pipe(
        first(),
        map((e) => e?.data?.loginUser),
        tap((e: any) => this.setSoredUser(e)),
        catchError((err) => {
          throw err;
        }),
      );
  }

  public logout() {
    this.apollo.client.clearStore().then(() => {
      this.apollo.client.cache.reset();
      this.clearStorage();
    });

    //To reset apollo context
    window.location.href = '/login';
  }

  public registerUser(item: UserInput): Observable<User> {
    return this.apollo
      .mutate<User>({
        mutation: RegisterUserMutation,
        variables: { item },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.registerUser),
        tap((e: any) => this.setSoredUser(e)),
      );
  }

  public verifyEmail(item: VerifyEmailInput): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: VerifyEmailMutation,
        variables: { item },
      })
      .pipe(
        first(),
        map((e: any) => e?.verifyEmail),
      );
  }

  public changePassword(item: ChangePasswordInput) {
    return this.apollo
      .mutate({
        mutation: ChangePasswordMutation,
        variables: { item },
      })
      .pipe(
        first(),
        map((e: any) => e?.verifyEmail || false),
      );
  }

  public requestResetPassword(username: string) {
    return this.apollo
      .mutate({
        mutation: RequestResetPasswordMutation,
        variables: { username },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.requestResetPassword || false),
      );
  }

  public resetPassword(data: {
    username: string;
    token: string;
    password: string;
  }) {
    return this.apollo
      .mutate({
        mutation: ResetPasswordMutation,
        variables: { ...data },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.resetPasswordMutation || false),
      );
  }

  public resendVerificationEmail(): void {
    this.apollo
      .mutate({
        mutation: ResendVerificationEmailMutation,
        variables: { id: this.user().id },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.resendVerificationEmail || false),
        tap((e) => {
          e
            ? this.ui.successSnack(
                `A verification email has been sent to ${this.user().email} !`,
              )
            : this.ui.warnSnack(`Account is already verified!`);
        }),
        catchError((err: any) => {
          this.ui.errorSnack(err.message);
          return of(false);
        }),
      )
      .subscribe();
  }

  public acceptInvitation(token: string) {
    return this.apollo
      .mutate({
        mutation: AcceptInvitationMutation,
        variables: { token },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.acceptInvitation || {}),
        catchError((err) => {
          this.ui.errorSnack(err.message);
          return of({});
        }),
      );
  }

  public rejectInvitation(token: string) {
    return this.apollo
      .mutate({
        mutation: RejectInvitationMutation,
        variables: { token },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.rejectInvitation || false),
      );
  }

  public signInWithGoogle(token: string) {
    return this.apollo
      .mutate({
        mutation: SignInWithGoogleMutation,
        variables: { token },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.signInGoogle),
        tap((e: any) => this.setSoredUser(e)),
      );
  }

  public signUpWithGoogle(token: string) {
    return this.apollo
      .mutate({
        mutation: SignUpWithGoogleMutation,
        variables: { token },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.signUpGoogle),
        tap((e: any) => this.setSoredUser(e)),
      );
  }

  public signInWithAppleId(token: string) {
    return this.apollo
      .mutate({
        mutation: SignInWithAppleIdMutation,
        variables: { token },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.signInApple),
        tap((e) => this.setSoredUser(e)),
      );
  }

  public signUpWithAppleId(token: string, firstName: string, lastName: string) {
    return this.apollo
      .mutate({
        mutation: SignUpWithAppleIdMutation,
        variables: { token, firstName, lastName },
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.signUpApple),
        tap((e) => this.setSoredUser(e)),
      );
  }

  public getInivtationData(token: String) {
    return this.apollo
      .query({
        query: ClubInvitationByToken,
        variables: { token },
        fetchPolicy: 'network-only',
      })
      .pipe(
        first(),
        map((e: any) => e?.data?.clubInvitationByToken),
        catchError((err) => {
          throw err;
        }),
      );
  }

  private clearStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
  }

  // #region roel and permission methods

  /**
   * @description this method is casesnsitive.. So the capital letters DOES MATTER
   */
  hasPermission(permission: `${Permission}` | Permission): boolean {
    return this.user().permissions.includes(permission);
  }

  /**
   * @description this method is casesnsitive.. So the capital letters DOES MATTER
   */
  hasRole(roleName: `${Roles}` | Roles): boolean {
    return this.user().roles.includes(roleName);
  }

  isAdmin(): boolean {
    return this.hasRole(Roles.SuperAdmin);
  }

  isManager(): boolean {
    return this.hasRole(Roles.Manager);
  }

  isTrainer(): boolean {
    return this.hasRole(Roles.Trainer);
  }

  isNewUser(): boolean {
    return this.hasRole(Roles.NewUser);
  }

  //#endregion
}
